import React from 'react';

const Macif = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="97"
    height="24"
    viewBox="0 0 97 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.3392 1.23797H96.539V5.82465H92.3392V1.23797ZM92.3392 14.4547V9.88989H96.539V14.4547H92.3392ZM88.1399 5.82465V1.23797H92.3397V5.82465H88.1399ZM92.3392 9.88989H85.4727V1.23797H80.3559V23.0725H85.4727V14.4547H92.3392V9.88989ZM48.656 23.0725V20.951L49.8382 23.0725H48.656ZM48.656 17.0971C50.6586 21.0289 55.0034 23.7615 60.0231 23.7615C64.0898 23.7615 67.722 21.9621 70.0146 19.1962L66.0083 16.342C64.6445 18.0189 62.4716 19.1079 60.0226 19.1079C55.8956 19.1079 52.5286 16.0091 52.5286 12.2108C52.5286 8.42384 55.8956 5.32505 60.0226 5.32505C62.4474 5.32505 64.6203 6.40214 65.9841 8.05673L69.9537 5.15788C67.6487 2.42572 64.0527 0.660002 60.0226 0.660002C55.0024 0.660002 50.6581 3.39216 48.6555 7.32394V17.0971H48.656ZM71.4631 23.0725V1.23797H76.5798V23.0725H71.4631ZM48.656 7.32442V17.0976C47.884 15.5666 47.4845 13.899 47.4852 12.2112C47.4852 10.4783 47.9073 8.81279 48.656 7.32442ZM48.656 20.9515L37.0587 0.238281L34.1871 5.33597L39.5448 14.8432H34.6092L35.4782 13.2774L32.6422 8.16834L24.304 23.0725H30.0116L32.0514 19.419H42.0671L44.1306 23.0725H48.656V20.9515ZM2.55837 23.0725V11.7335L5.11674 13.6108V23.0729H2.55837V23.0725ZM2.55837 8.35735L11.6571 15.1096L18.2579 10.2565V23.0729H23.3747V0.449616L11.6938 9.1457L2.55837 2.34878V8.35735ZM2.55837 2.34878V8.35735L0 6.45818V0.449616L2.55837 2.34878ZM2.55837 11.7335V23.0725H0V9.84525L2.55837 11.7335Z"
      fill="#475467"
    />
  </svg>
);

export default Macif;
